import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import moment from "moment";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

import { v4 as uuidv4 } from "uuid";

function ListadoApartados() {
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_APARTADOS = process.env.REACT_APP_URL_APARTADOS;
  const URL_TELAS_OC = process.env.REACT_APP_URL_TELAS_OC;
  const URL_INVENTARIOS_TELAS = process.env.REACT_APP_URL_INVENTARIOS_TELAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_TELAS_APARTADOS = process.env.REACT_APP_URL_TELAS_APARTADOS;
  const URL_APARTADOS_ACUMULADOS = process.env.REACT_APP_URL_APARTADOS_ACUMULADOS;
  const URL_ORDENES_CORTE = process.env.REACT_APP_URL_ORDENES_CORTE;
  const URL_TELAS_ORDENES_CORTE = process.env.REACT_APP_URL_TELAS_ORDENES_CORTE;
  const URL_TELAS_ORDENES_CORTES_ACUMULADOS = process.env.REACT_APP_URL_TELAS_ORDENES_CORTES_ACUMULADOS;
  const URL_ARTICULOS_ORDENES_CORTE = process.env.REACT_APP_URL_ARTICULOS_ORDENES_CORTE;

  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;

  const [apartados, setApartados] = useState([]);


  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [fecha, setFecha] = useState("");
  const [pedimento, setPedimento] = useState("");
  const [factura_cliente, setFacturaCliente] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [partida, setPartida] = useState("");
  const [aduana, setAduana] = useState("");
  const [fecha_aduana, setFechaAduana] = useState("");
  const [pais_origen, setPaisOrigen] = useState("");
  const [numero_apartado, setNumeroApartado] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");

  const [telasApartado, setTelasApartado] = useState([]);
  const [telasApartadoEdit, setTelasApartadoEdit] = useState([]);
  const [modalEditTelas, setModalEditTelas] = useState(false);
  const toggleEditTelas = () => setModalEditTelas(!modalEditTelas);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);
  const [totalDinero, setTotalDinero] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalOrdenCorte, setModalOrdenCorte] = useState(false);
  const toggleOrdenCorte = () => setModalOrdenCorte(!modalOrdenCorte);
  const [ordenes, setOrdenes] = useState([]);
  const [articulosOrdenes, setArticulosOrdenes] = useState([]);
  const [articulosUnicos, setArticulosUnicos] = useState([]);
  const [ordenCorte, setOrdenCorte] = useState("");
  const [fechaEntrega, setFechaEntrega] = useState(endDate);
  const [observacionesEntrega, setObservacionesEntrega] = useState("NA");
  const [idArticuloEntrega, setIdArticuloEntrega] = useState("");
  const [totalKilos, setTotalKilos] = useState("");
  const [totalMetros, setTotalMetros] = useState("");
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);
  const [dataFinalRollos, setDataFinalRollos] = useState([]);



  const [impuestos, setImpuestos] = useState("");
  const [observacionesNota, setObservacionesNota] = useState("NA");

  const [validaBoton, setValidaBoton] = useState(true);

  const [telasApartadoSurtido, setTelasApartadoSurtido] = useState([]);

  const [telasPorSurtir, setTelasPorSurtir] = useState([]);
  const [idsInventarioTelas, setIdsInventarioTelas] = useState([]);
  const [selectedPedido, setSelectedPedido] = useState("");
  const [pedidos, setPedidos] = useState([]);
  const [fecha_compromiso, setFechaCompromiso] = useState("");  
  const [fecha_pedido, setFechaPedido] = useState("");
  const [rollosTotales, setRollosTotales] = useState(0);
  const [total_kilos_pedido, setTotalKilosPedido] = useState(0);
  const [total_metros_pedido, setTotalMetrosPedido] = useState(0);
  const [total_general_pedido, setTotalGeneralPedido] = useState(0);
  const [cliente, setCliente] = useState("");
  const [idCliente, setIdCliente] = useState("");

  const [idApartadoSurtir, setIdApartadoSurtir] = useState("");
  const [idPedidoSurtir, setIdPedidoSurtir] = useState("");
  const [idClienteSurtir, setIdClienteSurtir] = useState("");

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInventarioTelas: "",
      telas: "",
      idTela: "",
      unidad: "",
      colores: "",
      cantidad: 0, 
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("Si");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(
        `${URL_APARTADOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allApartados = response.data;
        // Array para pagination
        let arrayTabla = allApartados
          .sort((a, b) => (a.idApartado < b.idApartado ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              pedidos: a.pedidos[0].idPedido,
              idPedido: a.pedidos[0]._id,
              clientes: a.pedidos[0].clientes[0].nombre_comercial,
              idClientes: a.pedidos[0].clientes[0]._id,
              observaciones: a.pedidos[0].observaciones,
              numero: a.idApartado,
              cortado:a.cortado,
              is_active:a.is_active
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setApartados(allApartados);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_PEDIDOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPedidos = res.data;
        setPedidos(allPedidos);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_INVENTARIOS_TELAS}Disponible`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTelasApartado = res.data;
        setTelasApartadoEdit(allTelasApartado);
        let result = allTelasApartado.map(({ _id }) => _id);
        setIdsInventarioTelas(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 60, 25);
    doc.text(`Listado de Apartados`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Cliente", "Pedido", "Apartao"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    doc.save(`Apartados.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Fecha: a.fecha,
          Cliente: a.clientes,
          Pedido: a.pedidos,
          Apartao: a.user,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoApartados";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoApartados",
        sheetFilter: ["Fecha", "Cliente", "Pedido", "Apartao"],
        sheetHeader: ["Fecha", "Cliente", "Pedido", "Apartao"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 60, 25);
    doc.text(`Listado de Apartados`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Cliente", "Pedido", "Apartao"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Apartados",
          email: mailTo,
          fileName: "ListadoApartados.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Apartados.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos
  ) {
    await axios
      .get(`${URL_TELAS_APARTADOS}/apartado/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTelasApartado = response.data;
        let arrayTabla = allTelasApartado
          .map((a) => {
            return {
              id: a.inventariosTelas[0]._id,
              telas:
                a.inventariosTelas[0].telas[0].codigo +
                " " +
                a.inventariosTelas[0].telas[0].nombre,
              unidad: a.inventariosTelas[0].telas[0].unidad,
              colores: a.inventariosTelas[0].colores[0].name,
              cantidad: a.inventariosTelas[0].cantidad,
              cantidadString: a.inventariosTelas[0].cantidad.toString(),
              idTela: a.inventariosTelas[0].telas[0]._id,
              idColor: a.inventariosTelas[0].colores[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        let cantidadRollos = dataFinal.length;

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idTela"] + item["idColor"]
          groups[val] = groups[val] || {
            idTela: item.idTela,
            idColores: item.idColores,
            cantidad: 0,
            rollos: 0,
            cantidadString: "",
          };
          groups[val].cantidad += item.cantidad;
          groups[val].cantidadString += item.cantidadString + " / ";
          groups[val].telas = item.telas;
          groups[val].unidad = item.unidad;
          groups[val].colores = item.colores;
          groups[val].descuento = item.descuento;
          groups[val].rollos += 1;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);


        let mts = agrupadoValues.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = agrupadoValues.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);


        const data = agrupadoValues.map((a) => {
          return [
            a.telas,
            a.colores,
            a.rollos,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            a.cantidadString,
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Apartado # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Cliente ${clientes}`, 20, 30);
        doc.text(`Pedido # ${pedidos}`, 20, 35);
        doc.text(
          `Metros ${new Intl.NumberFormat("en-US").format(
            TM
          )} / Rollos ${new Intl.NumberFormat("en-US").format(cantidadRollos)}`,
          20,
          40
        );
        doc.autoTable({
          head: [
            [
              "Tela",
              "Color",
              "Rollos",
              "Total",
              "Desgloce Rollos",
            ],
          ],
          body: dataPDFLimpia,
          startY: 45,
          foot: [["", "", "", "", "", ""]],
          showFoot: "lastPage",
        });

        doc.save(`Apartado-Resumen-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editApartados(event) {
    event.preventDefault();
    const URL_APARTADO_EDIT = `${process.env.REACT_APP_URL_APARTADOS}/${idEdit}`;
    axios
      .all([
        axios.patch(
          URL_APARTADO_EDIT,
          {
            fecha,
            pedimento,
            partida,
            observaciones,
            aduana,
            fecha_aduana,
            pais_origen,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Apartado",
            detalle: `${numero_apartado} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: false },
    { name: "Pedido", field: "pedidos", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if(activo){
      computedComments = computedComments.filter((e)=>
      e.is_active.includes(activo)
      ) 
    } 

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedidos" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
    activo
  ]);

  function EditTelas(idApartado, numero_apartado, idPedido) {

    setIdEdit(idApartado);
    setNumeroApartado(numero_apartado);
    setSelectedPedido(idPedido);



    pedidos.map((a) => {
      if (a._id == idPedido) {
        setFechaPedido(a.fecha);
        setFechaCompromiso(a.fecha_compromiso);
        setTotalKilosPedido(a.total_kilos);
        setTotalMetrosPedido(a.total_metros);
        setTotalGeneralPedido(a.total_general);
        setCliente(a.clientes[0].razon_social);
        setIdCliente(a.clientes[0]._id);
      }
    });
    
    axios
      .get(`${URL_TELAS_APARTADOS}/apartado/${idApartado}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTelas = res.data;
        let arrayTabla = allTelas
          .map((a) => {
            return {
              idArtApartado: a._id,
              idInventarioTelas: a.inventariosTelas[0]._id,
              idTela: a.inventariosTelas[0].telas[0]._id,
              tela: a.inventariosTelas[0].telas[0].codigo + " / " + a.inventariosTelas[0].telas[0].nombre + " / " + a.inventariosTelas[0].colores[0].name,
              unidad: a.inventariosTelas[0].telas[0].unidad,
              cantidad: a.inventariosTelas[0].cantidad,
              colores: a.inventariosTelas[0].colores[0]._id
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setTelasApartado(dataFinal);

        let mts = dataFinal.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleEditTelas();
  }




 

  function ActualizaTotales() {
    let mtsExistentes = telasApartado.filter((e) => e.unidad == "Metros");
    let mtsNuevos = inputFields.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = telasApartado.filter((e) => e.unidad == "Kilos");
    let KGNuevos = inputFields.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);

    let juntos = telasApartado.concat(inputFields)


    let tr = 0;
    juntos.map((a) => {
      if (a.telas != "") {
        return (tr = tr + 1);
      }
    });
    setRollosTotales(tr);
  }

 

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mtsExistentes = telasApartado.filter((e) => e.unidad == "Metros");
    let mtsNuevos = values.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = telasApartado.filter((e) => e.unidad == "Kilos");
    let KGNuevos = values.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInventarioTelas: "",
        telas: "",
        idTela: "",
        unidad: "",
        colores: "",
        cantidad: 0,
      },
    ]);
  };

  async function mandarTela(idApartado, idPedido, idCliente){
    axios
    .get(`${URL_ORDENES_CORTE}Pedido/${idPedido}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allOrdenes = response.data;
      setOrdenes(allOrdenes);
    })
    .catch((err) => {
      console.log(err);
    });

    await axios
    .get(`${URL_TELAS_APARTADOS}/apartado/${idApartado}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allTelasApartado = response.data;
      let arrayTabla = allTelasApartado
        .map((a) => {
          return {
            id: a.inventariosTelas[0]._id,
            telas:
              a.inventariosTelas[0].telas[0].codigo +
              " " +
              a.inventariosTelas[0].telas[0].nombre,
            unidad: a.inventariosTelas[0].telas[0].unidad,
            colores: a.inventariosTelas[0].colores[0].name,
            cantidad: a.inventariosTelas[0].cantidad,
            cantidadString: a.inventariosTelas[0].cantidad.toString(),
            idTela: a.inventariosTelas[0].telas[0]._id,
            idColor: a.inventariosTelas[0].colores[0]._id,
          };
        })
        .filter(function (el) {
          return el != null;
        });

      let dataFinal = Object.values(arrayTabla);
      setDataFinalRollos(dataFinal)
      let cantidadRollos = dataFinal.length;

      let agrupado = dataFinal.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idColor"] + item["idTela"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        idColor: item.idColor,
        idTela: item.idTela,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].telas = item.telas;
      groups[val].idTela = item.idTela;
      groups[val].idArticulo = item.idArticulo;
      groups[val].unidad = item.unidad;
      groups[val].idColor = item.idColor;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);

      let mts = CA.filter((e) => e.unidad == "Metros");
      let totalMts = mts.map((c) => parseFloat(c.cantidad));
      let TM = totalMts.reduce((t, total, index) => t + total, 0);
      setTotalMetros(TM);
      let KG = CA.filter((e) => e.unidad == "Kilos");
      let totalKG = KG.map((c) => parseFloat(c.cantidad));
      let TK = totalKG.reduce((t, total, index) => t + total, 0);
      setTotalKilos(TK);

    })
    .catch((err) => {
      console.log(err);
    });

    setIdApartadoSurtir(idApartado)
    setIdPedidoSurtir(idPedido)
    setIdClienteSurtir(idCliente)
    toggleOrdenCorte()
  }

  const saveTelas = async (event) => {
    if (fechaEntrega != "" && observacionesEntrega != "" && ordenCorte != "" && idArticuloEntrega != "") {
      setValidaBoton(false);
      toggleProgreso();
      

      let totalRollos = dataFinalRollos.length + cantidadAgrupado.length;
      try {
        await axios
          .post(
            URL_TELAS_ORDENES_CORTE,
            {
              fecha: fechaEntrega,
              ordenesCorte: ordenCorte,
              observaciones: observacionesEntrega,
              totalMetros,
              totalKilos,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            dataFinalRollos.map((a) => {
              if (a.idTela != "") {
                axios
                  .patch(
                    `${URL_INVENTARIOS_TELAS}/ordenesCorte/${a.id}`,
                    {
                      ordenesCorte: ordenCorte,
                      produccion: "Si",
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Telas Ordenes Corte",
                            detalle: `Ordenes Corte ${ordenCorte}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Telas Ordenes Corte",
                        detalle: `Ordenes Corte ${ordenCorte}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });

            // Pegamos a Apartados Acumulado

            cantidadAgrupado.map((w) => {
              axios
                .post(
                  `${URL_TELAS_ORDENES_CORTES_ACUMULADOS}`,
                  {
                    telasOrdenesCorte: data.data._id,
                    articulos: idArticuloEntrega,
                    telas: w.idTela,
                    colores: w.idColor,
                    cantidad: w.cantidad,
                    ordenesCorte: ordenCorte,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Telas Ordenes Corte",
                          detalle: `Ordenes Corte ${ordenCorte}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Terminamos de post a apartados acumulado
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  function BuscaTela(id, event) {
    if (event.target.value.length >= 24) {
      if (idsInventarioTelas.includes(event.target.value)) {
        if (
          inputFields.filter((e) => e.idInventarioTelas == event.target.value)
            .length > 0
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El rollo ya fue escaneado!",
            showConfirmButton: false,
          });
          const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
              i.idInventarioTelas = "";
            }
            return i;
          });
          setInputFields(newInputFields);
        } else {
          telasApartadoEdit.map((a) => {
            if (a._id == event.target.value) {
              let telasApartado = a._id;
              let tela = a.telas[0].codigo + " / " + a.telas[0].nombre;
              let idTela = a.telas[0]._id;
              let unidadTela = a.telas[0].unidad;
              let color = a.colores[0]._id;
              let cantidad = a.cantidad;
              handleChangeInputTela(
                id,
                telasApartado,
                tela,
                idTela,
                unidadTela,
                color,
                cantidad,
              );
            }
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El rollo no pertenece al pedido!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.idInventarioTelas = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.idInventarioTelas = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  const handleChangeInputTela = (
    id,
    telasApartado,
    tela,
    idTela,
    unidadTela,
    color,
    cantidad,
  ) => {



    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idInventarioTelas = telasApartado;
        i.telas = tela;
        i.idTela = idTela;
        i.unidad = unidadTela;
        i.colores = color;
        i.cantidad = cantidad;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

 async function borrarExistentes(idInventarioTelas){
    Swal.fire({
      title: "Estas seguro?",
      text: "Se va a Borrar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Borrar!",
    }).then(async(result) => {
      if (result.isConfirmed) {
     
      
      axios
      .patch(
        `${URL_INVENTARIOS_TELAS}/desApartar/${idInventarioTelas}`,
        {
          apartado: "No",
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
          },
        }
      ).then(()=>{

        const values = [...telasApartado];
        values.splice(
          values.findIndex((value) => value.idInventarioTelas === idInventarioTelas),
          1
        );
        setTelasApartado(values);
        let mtsExistentes = values.filter((e) => e.unidad == "Metros");
        let mtsNuevos = inputFields.filter((e) => e.unidad == "Metros");
        let mts = mtsExistentes.concat(mtsNuevos);
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
    
        let KGExistentes = values.filter((e) => e.unidad == "Kilos");
        let KGNuevos = inputFields.filter((e) => e.unidad == "Kilos");
        let KG = KGExistentes.concat(KGNuevos);
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);


          Swal.fire("Good job!", "Creado con exito", "success");

      }).catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
      }
    });
  }


  const saveTelasApartado = async (event) => {
    event.preventDefault();
      setValidaBoton(false);
      toggleProgreso();


      let totalRollos = inputFields.length 
      try {
            inputFields.map((a) => {
              if (a.telas != "") {
                axios
                  .patch(
                    `${URL_INVENTARIOS_TELAS}/apartado/${a.idInventarioTelas}`,
                    {
                      apartados: idEdit,
                      apartado: "Si",
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Apartado",
                            detalle: `Pedido ${selectedPedido}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Apartado",
                        detalle: `Pedido ${selectedPedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });

      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
  };

 function seleccionaOC(e){
   setOrdenCorte(e)

   axios
    .get(`${URL_ARTICULOS_ORDENES_CORTE}/orden/${e}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allArticulosOrdenes = response.data;
      let arrayArticulosUnicos = allArticulosOrdenes
        .map((a)=>{
          return{
            idArticulo: a.articulosPedido[0].articulos[0]._id,
            articuloNombre:
                a.articulosPedido[0].articulos[0].codigo + " / " + a.articulosPedido[0].articulos[0].codigoCliente +
                " / " +
                a.articulosPedido[0].articulos[0].nombre,
          }
        })
        
        .filter(function (el) {
          return el != null;
        });

      const data = Object.values(arrayArticulosUnicos);
      setArticulosOrdenes(data);

      let agrupadoUnicos = data.reduce(function (groups, item) {
        const val = item["idArticulo"];
        groups[val] = groups[val] || {
          idArticulo: item.idArticulo,
        };
        groups[val].idArticulo = item.idArticulo;
        groups[val].articuloNombre = item.articuloNombre;
        return groups;
      }, []);
      let dataFinalAgru = Object.values(agrupadoUnicos);
      setArticulosUnicos(dataFinalAgru);

      // setArticulosOrdenes(allArticulosOrdenes);
    })
    .catch((err) => {
      console.log(err);
    });
 }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.menu_produccion ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ApartadosCreate"
                >
                  Nuevo Apartado
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Apartado
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Apartados</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Apartados</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.razon_social}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                return (
                  <tr>
                    <td>{a.numero}</td>
                    <td>{a.fecha}</td>
                    <td>{a.clientes}</td>
                      <td>{a.observaciones}</td>
                    <td>{a.pedidos}</td>
                    <td>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={(e) =>
                          PDFOC(
                            a.fecha,
                            a.idClientes,
                            a.clientes,
                            a.observaciones,
                            a.numero,
                            a._id,
                            a.user,
                            a.pedidos
                          )
                        }
                      >
                        <i class="far fa-file-pdf"></i>
                      </Button>

                       {a.cortado =="No"?( 
                      <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={(e) => mandarTela(a._id, a.idPedido, a.idClientes)}
                    >
                      <i class="far fa-paper-plane"></i>
                    </Button>
                       ):undefined }

            {a.cortado =="No"?(
              <>
                        <Button
                        color="info"
                        id="Editar"
                        size="sm"
                        onClick={(e) =>
                          EditTelas(
                            a._id,
                            a.numero,
                            a.idPedido
                          )
                        }
                      >
                        <i class="fas fa-cart-plus"></i>
                      </Button>

                  </>
                      ):undefined }
                      <Baja
                        idStatus={a._id}
                        is_active={a.is_active}
                        URL_BAJA={
                          process.env.REACT_APP_URL_APARTADOS
                        }
                      />
                      

                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Apartado # {numero_apartado}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Pedimento</Label>
              <Input
                type="text"
                placeholder="Pedimento"
                value={pedimento}
                required
                onChange={(e) => {
                  setPedimento(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Factura Cliente</Label>
              <Input
                type="text"
                placeholder="Factura Cliente"
                value={factura_cliente}
                required
                onChange={(e) => {
                  setFacturaCliente(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Partida</Label>
              <Input
                type="text"
                placeholder="Partida"
                value={partida}
                required
                onChange={(e) => {
                  setPartida(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Aduana</Label>
              <Input
                type="text"
                placeholder="Aduana"
                value={aduana}
                required
                onChange={(e) => {
                  setAduana(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Fecha Aduana</Label>
              <Input
                type="date"
                placeholder="Fecha Aduana"
                value={fecha_aduana}
                required
                onChange={(e) => {
                  setFechaAduana(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Pais Origen</Label>
              <Input
                type="text"
                placeholder="Pais Origen"
                value={pais_origen}
                required
                onChange={(e) => {
                  setPaisOrigen(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editApartados}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditTelas}
        toggle={toggleEditTelas}
      >
        <ModalHeader toggle={toggleEditTelas}>
          <h4>Editar Telas Apartado # {numero_apartado}</h4>
          <h4>
            TOTALES {total_kilos_edit} kg. / {total_metros_edit} mts.
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Codigo</Label>
              </Col>
              <Col md={4}>
                <Label className="mr-sm-2">Telas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Unidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>

            </Row>

          {/* Telas Existentes*/}

          {telasApartado.map((ap) => (
            <div key={ap.idArtApartado}>
              <Row>
              <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="codigo"
                    type="string"
                    value={ap.idInventarioTelas}
                    disabled
                  ></Input>
                </Col>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="tela"
                    type="string"
                    value={ap.tela}
                    disabled
                  ></Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="unidad"
                    type="string"
                    value={ap.unidad}
                    disabled
                  ></Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="string"
                    value={ap.cantidad}
                    disabled
                  ></Input>
                </Col>
                <Button
                    style={{marginTop:"0px"}}
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={() => borrarExistentes(ap.idInventarioTelas)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
              </Row>
            </div>
          ))}

          {/* Agregar mas telas */}

          {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="idInventarioTelas"
                      type="string"
                      value={inputField.idInventarioTelas}
                      onChange={(event) => {
                        BuscaTela(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="telas"
                      type="string"
                      value={inputField.telas}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="unidad"
                      type="string"
                      value={inputField.unidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="string"
                      value={inputField.cantidad}
                      disabled
                    ></Input>
                  </Col>

                    <Button
                    style={{marginTop:"0px"}}
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                     style={{marginTop:"0px"}}
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                </Row>
              </div>
            ))}
          <Row>
            <Col md={12}>
              <h4 id="logoutBoton">
                TOTALES{" "}
                {new Intl.NumberFormat("en-US").format(total_kilos_edit)} kg. /{" "}
                {new Intl.NumberFormat("en-US").format(total_metros_edit)} mts.
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={saveTelasApartado} tabindex="-1">
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios minutos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalOrdenCorte} toggle={toggleOrdenCorte}>
        <ModalHeader toggle={toggleOrdenCorte}>
          <h4>Entregar Tela</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Orden de Corte
              </Label>
              <Input
                bsSize="sm"
                type="select"
                value={ordenCorte}
                onChange={(e) => {
                  seleccionaOC(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {ordenes
                  .sort((a, b) => (a.idOrdenesCorte > b.idOrdenesCorte ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.idOrdenesCorte}</option>;
                  })}
              </Input>
            </Col>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Articulo
              </Label>
              <Input
                bsSize="sm"
                type="select"
                value={idArticuloEntrega}
                onChange={(e) => {
                  setIdArticuloEntrega(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {articulosUnicos
                  .sort((a, b) => (a.articuloNombre > b.articuloNombre ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idArticulo}>{a.articuloNombre}</option>;
                  })}
              </Input>
            </Col>
            </Row>
            <br />
            <Row>
        <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Fecha
              </Label>
              <Input
                bsSize="sm"
                type="date"
                value={fechaEntrega}
                onChange={(e) => {
                  setFechaEntrega(e.target.value);
                }}
              />
               
            </Col>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                bsSize="sm"
                type="text"
                value={observacionesEntrega}
                onChange={(e) => {
                  setObservacionesEntrega(e.target.value);
                }}
              />
               
            </Col>
          </Row>
            <br />
          <Button size="sm" color="success" onClick={e=>saveTelas()} tabindex="-1">
            Guardar
          </Button>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoApartados;
